import React, { useEffect, useState } from 'react';
import styles from './index.module.css'
import cls from 'classnames'
import { Link } from "gatsby"
const Header = ({ page }) => {
  const [top, setTop] = useState(true)
  useEffect(()=>{
    window.addEventListener('scroll',()=>{
      console.log(1111,window.pageYOffset)
      if(window.pageYOffset < 50){
        setTop(true)
      }else{
        setTop(false)
      }
    })
  },[])
  return (
    <div className={cls(styles.topBox, !top ? styles.topBoxNotZero: null)}>
      <div className={styles.logo}>
        <img
          src={
            "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/January/29/4c1cb08bd3e24371bf5832d3bb87cba5"
          }
          alt="LOGO"
          style={{ width: "100%", height: "100%" }}
        />
      </div>
      <div className={styles.list}>
        <div
          className={cls(
            styles.listItem,
            page === "Home" ? styles.listItemActive : {}
          )}
        >
          <Link to="/">首页</Link>
        </div>
        <div
          className={cls(
            styles.listItem,
            page === "SmartProperty" ? styles.listItemActive : {}
          )}
        >
          <Link to="/smart-property/">智慧物业</Link>
        </div>
        <div
          className={cls(
            styles.listItem,
            page === "SmartLife" ? styles.listItemActive : {}
          )}
        >
          <Link to="/smart-life/">智慧生活</Link>
        </div>
        {/* <div
          className={cls(
            styles.listItem,
            page === "DownLoad" ? styles.listItemActive : {}
          )}
        >
          <Link to="/download">APP下载</Link>
        </div> */}
        <div
          className={cls(
            styles.listItem,
            page === "About" ? styles.listItemActive : {}
          )}
        >
          <Link to="/about/">关于我们</Link>
        </div>
      </div>
    </div>
  )
}

export default Header
