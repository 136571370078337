import { Link } from "gatsby"
import React from "react"
import styles from "./index.module.css"

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.footerFlex2}>
          <div className={styles.footerTitle}>智慧社区解决方案</div>
          <div className={styles.footerItem}>
            <Link to="/smart-property/" className={styles.footerItem}>
              智慧物业
            </Link>
          </div>
          <div className={styles.footerItem}>
            <Link to="/smart-life/" className={styles.footerItem}>
              智慧生活
            </Link>
          </div>
          <div>
            <Link to="/about/" className={styles.footerItem}>
              关于我们
            </Link>
          </div>
        </div>
        <div className={styles.footerFlex3}>
          <div className={styles.footerTitle}>文档支持</div>
          <div className={styles.footerItem}>
            <Link to="/agreement/" className={styles.footerItem}>
              用户协议
            </Link>
          </div>
          <div className={styles.footerItem}>
            <Link to="/privacy/" className={styles.footerItem}>
              隐私支持
            </Link>
          </div>
        </div>
        <div className={styles.footerFlex2}>
          <div className={styles.footerTitle}>联每户，美好生活缔造者</div>
          <div className={styles.footerItem}>
            公司名称：杭州联每户网络科技有限公司
          </div>
          <div className={styles.footerItem}>
            联系地址：浙江省杭州市余杭区文一西路1288号海创科技中心1号楼1206
          </div>
          <div className={styles.footerItem}>联系方式：0571-87703081</div>
          <div className={styles.footerItem}>公司邮箱：18017110789@163.com</div>
        </div>
      </div>
      <div className={styles.footerLine}>
        <a
          target="_blank"
          style={{ color: "#fff" }}
          href="https://beian.miit.gov.cn"
        >
          浙ICP备2021000299号
        </a>
        <br />© 2014-2021 杭州联每户网络科技有限公司 版权所有
      </div>
    </div>
  )
}

export default Footer
